import React from 'react';
import { useSpotify } from '../context/SpotifyContext';

const Overlay2 = () => {
  const { trackData, queueInfo } = useSpotify();

  if (!trackData) return <p className="text-center text-white">Aucune musique en cours de lecture</p>;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {trackData && !trackData.message ? (
        <div className="flex items-center bg-gray-200 rounded-lg shadow-lg p-4 w-full max-w-sm mb-4">
          <div className="flex-1">
            <p className="text-lg font-bold text-gray-800">{trackData.name || 'Titre inconnu'}</p>
            <p className="text-sm text-gray-600">{trackData.artist || 'Artiste inconnu'}</p>
          </div>
          {trackData.albumCover && (
            <img
              src={trackData.albumCover}
              alt="Couverture de l'album"
              className="w-16 h-16 rounded-md ml-4"
            />
          )}
        </div>
      ) : (
        <p className="text-center text-white">Aucune musique en cours de lecture</p>
      )}

      {queueInfo && (
        <div className="bg-gray-200 rounded-lg shadow-lg p-4 w-full max-w-sm">
          {!queueInfo.nextTrack ?? (
            <p className="text-lg font-bold text-gray-800">File d'attente : {queueInfo.queueLength} piste{queueInfo.queueLength > 1 ? 's' : ''}</p>
          )}
          {queueInfo.nextTrack ? (
            <div className="mt-2">
              <p className="text-sm text-gray-600">
                Prochain son : {queueInfo.nextTrack.title}
              </p>
              <p className="text-sm text-gray-600">
                Ajouté par : {queueInfo.nextTrack.username}
              </p>
            </div>
          ) : (
            <p className="text-sm text-gray-600">La file d'attente est vide</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Overlay2;