import React, { createContext, useState, useEffect, useContext } from 'react';

const SpotifyContext = createContext();

export const SpotifyProvider = ({ children }) => {
  const [trackData, setTrackData] = useState(null);
  const [queueInfo, setQueueInfo] = useState(null);
  const [allQueueInfo, setAllQueueInfo] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchCurrentlyPlaying = async () => {
    try {
      const response = await fetch(`${apiUrl}/current-track`);
      const data = await response.json();

      if (response.ok) {
        setTrackData(data);
      } else {
        console.error('Erreur depuis le back-end:', data.error || data.message);
        setTrackData(null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de la piste:', error);
      setTrackData(null);
    }
  };

  const fetchQueueInfo = async () => {
    try {
      const response = await fetch(`${apiUrl}/queue-info`);
      const data = await response.json();

      if (response.ok) {
        setQueueInfo(data);
      } else {
        console.error('Erreur lors de la récupération des informations de la file d\'attente:', data.error);
        setQueueInfo(null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de la file d\'attente:', error);
      setQueueInfo(null);
    }
  };

  const fetchAllQueueInfo = async () => {
    try {
      const response = await fetch(`${apiUrl}/all-queue-info`);
      const data = await response.json();

      if (response.ok) {
        setAllQueueInfo(data);
      } else {
        console.error('Erreur lors de la récupération des informations de la file d\'attente:', data.error);
        setAllQueueInfo(null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de la file d\'attente:', error);
      setAllQueueInfo(null);
    }
  };

  useEffect(() => {
    fetchCurrentlyPlaying();
    fetchQueueInfo();
    fetchAllQueueInfo();

    const intervalId = setInterval(() => {
      fetchCurrentlyPlaying();
      fetchQueueInfo();
      fetchAllQueueInfo();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SpotifyContext.Provider value={{ trackData, queueInfo, allQueueInfo }}>
      {children}
    </SpotifyContext.Provider>
  );
};

export const useSpotify = () => useContext(SpotifyContext);