import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// CONTEXT
import { SpotifyProvider } from './context/SpotifyContext';

// COMPONENTS
import Home from './components/Home';
import Callback from './components/Callback';
import Overlay1 from './components/Overlay1';
import Overlay2 from './components/Overlay2';

function App() {
  return (
    <SpotifyProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/overlay1" element={<Overlay1 />} />
            <Route path="/overlay2" element={<Overlay2 />} />
            <Route path="/callback" element={<Callback />} />
          </Routes>
        </div>
      </Router>
    </SpotifyProvider>
  );
}

export default App;
