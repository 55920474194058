import React, { useEffect, useState } from 'react';
import { useSpotify } from '../context/SpotifyContext';

const Home = () => {
    const { allQueueInfo } = useSpotify();
    const [cachedAllQueueInfo, setCachedAllQueueInfo] = useState([]);

    useEffect(() => {
        if (!allQueueInfo) return;

        const filteredQueueInfo = allQueueInfo.filter(
            (track) => track.MQ_SPOTIFY_TRACK_URI !== undefined && track.MQ_SPOTIFY_TRACK_URI !== null
        );

        if (
            cachedAllQueueInfo.length === filteredQueueInfo.length &&
            cachedAllQueueInfo[0]?.MQ_ID === filteredQueueInfo[0]?.MQ_ID &&
            cachedAllQueueInfo[cachedAllQueueInfo.length - 1]?.MQ_ID === filteredQueueInfo[filteredQueueInfo.length - 1]?.MQ_ID
        ) {
            // Ne rien faire si la longueur, le premier et le dernier élément n'ont pas changé
            return;
        }
    
        // Mettre à jour la liste mise en cache si un changement est détecté
        setCachedAllQueueInfo(allQueueInfo || []);
    }, [allQueueInfo, cachedAllQueueInfo]);
    
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Prochains sons Spotify</h1>
            {cachedAllQueueInfo.length > 0 ? (
            <ul className="space-y-4">
                {cachedAllQueueInfo.map((track) => (
                <li
                    key={track.MQ_ID}
                    className="bg-white p-4 rounded-lg shadow-md flex items-start space-x-4"
                >
                    {/* Image de l'album */}
                    <img
                    src={track.MQ_SPOTIFY_TRACK_URL_COVER}
                    alt={`${track.MQ_SPOTIFY_TRACK_ALBUM} cover`}
                    className="w-20 h-20 rounded-md object-cover"
                    />
                    <div className="flex-1">
                    {/* Titre et artiste */}
                    <p className="text-lg font-semibold text-gray-700">
                        {track.MQ_SPOTIFY_TRACK_TITLE || track.MQ_TRACK_INFO}
                    </p>
                    <p className="text-sm text-gray-500">Artiste : {track.MQ_SPOTIFY_TRACK_ARTIST || 'Inconnu'}</p>
                    <p className="text-sm text-gray-500">Album : {track.MQ_SPOTIFY_TRACK_ALBUM || 'Inconnu'}</p>
                    <p className="text-sm text-gray-400">
                        Ajouté à la file : {new Date(track.MQ_ADDED_AT).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500">Ajouté par : {track.MQ_TWITCH_USERNAME}</p>
                    </div>
                    {/* Lien vers Spotify */}
                    <a
                    href={`https://open.spotify.com/track/${track.MQ_SPOTIFY_TRACK_URI.split(':').pop()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline self-center"
                    >
                        Écouter
                    </a>
                </li>
                ))}
            </ul>
            ) : (
            <p className="text-gray-500">Aucune piste dans la file d'attente pour le moment.</p>
            )}
        </div>
    );
};

export default Home;