import React, { useEffect, useState, useRef } from 'react';
import { useSpotify } from '../context/SpotifyContext';

// ICONS
import NoMusicIcon from '../assets/icons/no_music.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';

const Overlay1 = () => {
  const { trackData, queueInfo } = useSpotify();
  const [cachedTrackData, setCachedTrackData] = useState(trackData || {});
  const [cachedQueueInfo, setCachedQueueInfo] = useState(queueInfo || {});

  const titleRef = useRef(null);
  const artistRef = useRef(null);
  const nextTrackTitleRef = useRef(null);
  const nextTrackUsernameRef = useRef(null);

  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
  const [isArtistOverflowing, setIsArtistOverflowing] = useState(false);
  const [isNextTrackTitleOverflowing, setIsNextTrackTitleOverflowing] = useState(false);
  const [isNextTrackUsernameOverflowing, setIsNextTrackUsernameOverflowing] = useState(false);

  const [titleOverflowAmount, setTitleOverflowAmount] = useState(0);
  const [artistOverflowAmount, setArtistOverflowAmount] = useState(0);
  const [nextTrackTitleOverflowAmount, setNextTrackTitleOverflowAmount] = useState(0);
  const [nextTrackUsernameOverflowAmount, setNextTrackUsernameOverflowAmount] = useState(0);

  const [animationStep, setAnimationStep] = useState(0);
  const [queueAnimationStep, setQueueAnimationStep] = useState(0);

  const [isQueueDrawerVisible, setIsQueueDrawerVisible] = useState(false);

  const [queueDrawerClosing, setQueueDrawerClosing] = useState(false);
  const [mainDrawerClosing, setMainDrawerClosing] = useState(false);

  useEffect(() => {
    if (trackData && trackData.name !== cachedTrackData.name) {
      setAnimationStep(1);
    }
  }, [trackData, cachedTrackData]);

  useEffect(() => {
    if (animationStep === 1) {
      // Close queue drawer
      setQueueDrawerClosing(true);
      setTimeout(() => {
        setAnimationStep(2);
      }, 500);
    } else if (animationStep === 2) {
      // Close main drawer
      setMainDrawerClosing(true);
      setTimeout(() => {
        setAnimationStep(3);
      }, 500);
    } else if (animationStep === 3) {
      // Shrink image
      setTimeout(() => {
        setAnimationStep(4);
      }, 1000);
    } else if (animationStep === 4) {
      // Update track data
      setCachedTrackData(trackData);
      setAnimationStep(5);
    } else if (animationStep === 5) {
      // Expand image
      setTimeout(() => {
        setAnimationStep(6);
      }, 1000);
    } else if (animationStep === 6) {
      // Open main drawer
      setMainDrawerClosing(false);
      setTimeout(() => {
        setAnimationStep(7);
      }, 500);
    } else if (animationStep === 7) {
      // Open queue drawer
      setQueueDrawerClosing(false);
      setTimeout(() => {
        setAnimationStep(0);
      }, 500);
    }
  }, [animationStep, trackData]);

  useEffect(() => {
    if (queueInfo && cachedQueueInfo) {
      const queueChanged =
        queueInfo.queueLength !== cachedQueueInfo.queueLength ||
        (queueInfo.nextTrack && cachedQueueInfo.nextTrack && (
          queueInfo.nextTrack.title !== cachedQueueInfo.nextTrack.title ||
          queueInfo.nextTrack.username !== cachedQueueInfo.nextTrack.username
        )) ||
        (queueInfo.nextTrack && !cachedQueueInfo.nextTrack) ||
        (!queueInfo.nextTrack && cachedQueueInfo.nextTrack);

        if (queueChanged) {
          setQueueAnimationStep(1);
        }
    }
  }, [queueInfo, cachedQueueInfo]);

  useEffect(() => {
    if (queueAnimationStep === 1) {
      // Ferme le tiroir de la file d'attente
      setQueueDrawerClosing(true);
      setTimeout(() => {
        setQueueAnimationStep(2);
      }, 500);
    } else if (queueAnimationStep === 2) {
      // Met à jour les données de la file d'attente
      setCachedQueueInfo(queueInfo);
      setQueueAnimationStep(3);
    } else if (queueAnimationStep === 3) {
      // Ouvre le tiroir de la file d'attente
      setQueueDrawerClosing(false);
      setTimeout(() => {
        setQueueAnimationStep(0);
      }, 500);
    }
  }, [queueAnimationStep, queueInfo]);

  useEffect(() => {
    if (titleRef.current) {
      const overflow = titleRef.current.scrollWidth - titleRef.current.clientWidth;
      setIsTitleOverflowing(overflow > 0);
      setTitleOverflowAmount(overflow);
    }
    if (artistRef.current) {
      const overflow = artistRef.current.scrollWidth - artistRef.current.clientWidth;
      setIsArtistOverflowing(overflow > 0);
      setArtistOverflowAmount(overflow);
    }
    if (nextTrackTitleRef.current) {
      const overflow = nextTrackTitleRef.current.scrollWidth - nextTrackTitleRef.current.clientWidth;
      setIsNextTrackTitleOverflowing(overflow > 0);
      setNextTrackTitleOverflowAmount(overflow);
    }
    if (nextTrackUsernameRef.current) {
      const overflow = nextTrackUsernameRef.current.scrollWidth - nextTrackUsernameRef.current.clientWidth;
      setIsNextTrackUsernameOverflowing(overflow > 0);
      setNextTrackUsernameOverflowAmount(overflow);
    }
  }, [cachedTrackData, cachedQueueInfo]);

  const marqueeAnimation = (overflowAmount) => {
    const moveDuration = 3; // Duration to move text
    const pauseDuration = 4; // Pause duration at each end
    const totalDuration = (pauseDuration + moveDuration) * 2; // Total animation duration

    const keyframes = `
      @keyframes marquee-${overflowAmount} {
        0% {
          transform: translateX(0);
        }
        ${(pauseDuration / totalDuration) * 100}% {
          transform: translateX(0);
        }
        ${((pauseDuration + moveDuration) / totalDuration) * 100}% {
          transform: translateX(-${overflowAmount}px);
        }
        ${(((pauseDuration + moveDuration) + pauseDuration) / totalDuration) * 100}% {
          transform: translateX(-${overflowAmount}px);
        }
        100% {
          transform: translateX(0);
        }
      }
    `;

    return {
      animation: `marquee-${overflowAmount} ${totalDuration}s linear infinite`,
      keyframes: keyframes,
    };
  };

  const drawerClassName = 'bg-white px-8 py-4 rounded-l-md rounded-r-none shadow-md h-28 w-96 flex flex-col justify-center transition-transform duration-500 ease-in-out origin-right';

  const drawerScaleClass = mainDrawerClosing ? 'scale-x-0' : 'scale-x-100';

  const imageClassName = 'w-40 h-40 rounded-lg transform transition-transform duration-1000 ease-in-out';

  const imageScaleClass = animationStep === 3 || animationStep === 4 ? 'scale-0' : 'scale-100';

  const titleAnimation = isTitleOverflowing
    ? marqueeAnimation(titleOverflowAmount)
    : {};
  const artistAnimation = isArtistOverflowing
    ? marqueeAnimation(artistOverflowAmount)
    : {};
  const nextTrackTitleAnimation = isNextTrackTitleOverflowing
    ? marqueeAnimation(nextTrackTitleOverflowAmount)
    : {};
  const nextTrackUsernameAnimation = isNextTrackUsernameOverflowing
    ? marqueeAnimation(nextTrackUsernameOverflowAmount)
    : {};

  const queueDrawerClassName = `${cachedQueueInfo?.queueLength > 0 ?? 'py-4'} h-12 -mt-4 flex justify-center transition-transform duration-500 ease-in-out origin-top`;

  const queueDrawerScaleClass = queueDrawerClosing ? 'scale-y-0' : 'scale-y-100';

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="relative items-center">
        {/* Main Drawer and Image */}
        <div className="flex items-center">
          {/* Main drawer */}
          <div className={`${drawerClassName} ${drawerScaleClass}`}>
            <div className="overflow-hidden" ref={titleRef}>
              <p
                className="text-2xl font-bold text-gray-800 whitespace-nowrap"
                style={isTitleOverflowing ? { animation: titleAnimation.animation } : {}}
              >
                {cachedTrackData?.name || 'Aucune musique en cours'}
              </p>
              {isTitleOverflowing && <style>{titleAnimation.keyframes}</style>}
            </div>
            <div className="overflow-hidden" ref={artistRef}>
              <p
                className="text-xl font-medium text-gray-600 whitespace-nowrap"
                style={isArtistOverflowing ? { animation: artistAnimation.animation } : {}}
              >
                {cachedTrackData?.artist || ''}
              </p>
              {isArtistOverflowing && <style>{artistAnimation.keyframes}</style>}
            </div>
          </div>
          {/* Image */}
          <img
            src={cachedTrackData?.albumCover || NoMusicIcon}
            alt="Couverture de l'album"
            className={`${imageClassName} ${imageScaleClass}`}
          />
        </div>

        {/* Queue Drawer */}
        <div className={`${queueDrawerClassName} ${queueDrawerScaleClass} absolute`}>
          <div className="bg-white px-2 rounded-md shadow-md w-80 flex items-center justify-center">
            {cachedQueueInfo?.queueLength > 0 ? (
              <>
                <div className="flex items-center gap-2 w-1/2">
                  <PlayIcon className="w-8 h-8 stroke-green-spotify flex-shrink-0" />
                  <div className="overflow-hidden flex-1 min-w-0" ref={nextTrackTitleRef}>
                    <p
                      className="text-md font-medium text-gray-600 whitespace-nowrap"
                      style={isNextTrackTitleOverflowing ? { animation: nextTrackTitleAnimation.animation } : {}}
                    >
                      {cachedQueueInfo?.nextTrack?.title || 'N/A'}
                    </p>
                    {isNextTrackTitleOverflowing && <style>{nextTrackTitleAnimation.keyframes}</style>}
                  </div>
                </div>
                <div className="flex items-center gap-2 w-1/2" ref={nextTrackUsernameRef}>
                  <UserIcon className="w-7 h-7 fill-black flex-shrink-0" />
                  <div className="overflow-hidden flex-1 min-w-0" ref={nextTrackUsernameRef}>
                    <p
                      className="text-md font-medium text-gray-600 whitespace-nowrap"
                      style={isNextTrackUsernameOverflowing ? { animation: nextTrackUsernameAnimation.animation } : {}}
                    >
                      {cachedQueueInfo?.nextTrack?.username || 'N/A'}
                    </p>
                    {isNextTrackUsernameOverflowing && <style>{nextTrackUsernameAnimation.keyframes}</style>}
                  </div>
                </div>
              </>
            ) : (
              <p className="text-lg text-gray-800">Aucune piste en attente</p>
            )}
          </div>
          <div className="bg-green-spotify rounded-md ml-2 h-12 w-12 flex items-center justify-center">
            <p className="text-lg font-bold text-white">
              {cachedQueueInfo?.queueLength}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay1;