import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Callback = () => {
    const [authCode, setAuthCode] = useState('');
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        setAuthCode(code || '');
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(authCode);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white px-4">
            <h1 className="text-3xl font-bold text-center mb-6">Code d'authentification Spotify</h1>
            
            <div className="flex items-center justify-center bg-gray-800 p-4 rounded-lg shadow-md mb-4">
                <p className="text-lg font-semibold text-pink-500 mr-4">
                {authCode || 'Pas de code trouvé'}
                </p>
                {authCode && (
                <Tooltip title={copied ? 'Copié !' : 'Copier'}>
                    <IconButton onClick={handleCopy} color="primary">
                    <ContentCopyIcon className="text-white" />
                    </IconButton>
                </Tooltip>
                )}
            </div>
            
            {copied && (
                <p className="text-sm text-green-400 mb-4">Code copié dans le presse-papiers !</p>
            )}
        
            <a href="/" className="text-blue-400 hover:underline">
                Retour à la home
            </a>
        </div>
    );
};

export default Callback;